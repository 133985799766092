@import 'app/styles/breakpoints';
@import 'app/styles/global/variables';

.slotsBlock {
  position: relative;
  display: grid;
  gap: 5px;
  grid-template-rows: 0.1fr 0.9fr;
  align-self: start;
}

.slotsWrapper {
  @media screen and (max-width: map-get($breakpoints, md)) {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 125%;

    &::-webkit-scrollbar {
      width: 0;
      height: 100%;
    }
  }
}

.notificationButton {
  cursor: pointer;
  border-bottom: 1px solid black;
}

.slots {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.dateBlock {
  display: grid;
  grid-template-rows: auto auto;
}

.superVision {
  background: $color-gray-light;
  border: 1px solid $color-dot-grey !important;

  &:hover {
    color: $color-dot-blue;
    border: 1px solid $color-dot-blue !important;
  }
}

.darkSlot {
  color: white;
  background: transparent;
}

.darkBtn {
  background: $color-blue-dark;
  border: 1px solid var(--primary-btn-color);
  color: var(--primary-btn-color);
}

.textChips {
  font-size: 12px;
}

.textChipsSuper {
  color: white !important;
}

.listSlots {
  display: flex;
  gap: 13px;
  align-items: flex-start;
}

.slotItem {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.specFirst {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 3fr;
}

.specSecond {
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 3fr;
}

.specLineItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
