@import 'app/styles/breakpoints';

.root {
  position: relative;
  width: 100%;

  &:first-child {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      color: #e8e8e8;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      color: #e8e8e8;
    }
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    color: #e8e8e8;
  }
}

.aboutSpec {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%
}

.rootDark {
  background-color: var(--card-dark);
  border-radius: 20px;
  color: white !important;
  padding: 20px 50px;

  &:first-child {
    &:after {
      width: 0 !important;
    }
  }

  &:before {
    width: 0 !important;
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: none;
  }
}

.wrapperForm {
  display: grid;
  padding: 20px 0;
  gap: 0 21px;
  grid-template-areas:
    'IMAGE NAME INFO BUTTONS'
    'IMAGE SPEC SPEC BUTTONS'
    'IMAGE SLOTS SLOTS BUTTONS';
  grid-template-columns: 180px 450px 1fr 180px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: grid;
    flex-direction: column;
    grid-template-columns: auto auto;
    gap: 20px 20px;
    grid-template-areas:
      'IMAGE NAME'
      'IMAGE INFO'
      'SPEC SPEC'
      'SLOTS SLOTS'
      'BUTTONS BUTTONS';
  }

  .mobileCredentials {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: map-get($breakpoints, sm)) {
    grid-template-columns: 105px 1fr;
    gap: 10px 10px;
  }
}

.darkWrapperForm {
  display: grid;
  padding: 20px 0;
  gap: 0 21px;
  grid-template-areas:
    'IMAGE NAME INFO BUTTONS'
    'IMAGE SPEC SPEC SPEC'
    'IMAGE SLOTS SLOTS SLOTS';
  grid-template-columns: 100px 1fr 275px auto;
}

.nameBlock {
  grid-area: NAME;
  display: grid;
  cursor: pointer;
  line-height: 1.1;

  @media screen and (max-width: map-get($breakpoints, md)) {
    width: 50%;
    line-height: 1.25;
  }
}

.nameBlockDark {
  color: var(--white-color) !important;
}

.infoSpecBloc {
  justify-items: start;
  grid-area: KIND;
  white-space: nowrap;
  display: grid;
  gap: 5px;
  grid-template-rows: 0.5fr 0.5fr;
}

.wrapperImage {
  grid-area: IMAGE;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 180px;
  max-height: 254px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    max-height: 150px;
    max-width: 105px;
  }
}

.wrapperBtns {
  grid-area: BUTTONS;
  min-width: 156px;

  @media screen and (max-width: map-get($breakpoints, md)) {
    display: flex;
    flex-direction: row;
    width: 95%;
  }
}

.darkWrapperBtns {
  min-width: 135px;
}

.wrapperInfo {
  grid-area: INFO;
}

.wrapperSpec {
  grid-area: SPEC;
}

.wrapperSlot {
  grid-area: SLOTS;
}

.darkChips {
  background: transparent;
  color: var(--white-color) !important;
}
